<template>
  <div class="h-full bg-white relative">
    <GMapMap
      ref="mapRef"
      :zoom="zoom"
      :options="mapOptions"
      map-type-id="roadmap"
      :center="mapCenter"
      class="w-full h-full"
      @tilesloaded="onTilesLoaded"
      @load="onMapLoaded"
    >
      <GMapMarker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :clickable="true"
        :icon="marker.isActive ? activeMarkerIcon : defaultMarkerIcon"
        @click="handleMarkerClick(index)"
      />
      <GMapMarker
        v-if="props.userPosition"
        :position="props.userPosition"
        :icon="userPositionIcon"
      />
    </GMapMap>
    <div class="absolute bottom-10 left-0 w-full h-[20%]">
      <swiper
        :spaceBetween="25"
        :centeredSlides="true"
        :slidesPerView="1.3"
        :loop="true"
        :autoplay="{ delay: delay, disableOnInteraction: false }"
        :effect="'none'"
        :modules="[Autoplay, EffectFade]"
        :touchRatio="1"
        :followFinger="true"
        :threshold="10"
        class="mySwiper h-full px-4"
        @swiper="onSwiper"
        @slideChangeTransitionStart="onSlideChangeStart"
        @touchEnd="onTouchEnd"
        @touchStart="onTouchStart"
      >
        <swiper-slide
          v-for="(news, index) in newsList"
          :key="index"
          class="h-full"
        >
          <div
            class="w-full h-full bg-neutral-100 relative rounded-lg overflow-hidden"
            @click="selectedItem(news)"
          >
            <MediaLoader
              type="image"
              :src="news?.image ? news.image[0]?.url : 'https://picsum.photos/200/300'"
              :alt="news.title"
              class="w-full h-full object-cover"
            />
            <div
              class="absolute w-full h-full px-[4.5vw] pt-[3.8vw] pb-[2.2vw] text-white top-0 right-0 flex items-center bg-neutral-800/35"
            >
              <div class="w-full leading-tight">
                <h3 class="text-[2.2vh] font-bold">{{ news.title }}</h3>
                <p class="text-[1.2vh] font-semibold mt-[1vw]">
                  {{ news.abstract }}
                </p>
              </div>
              <div class="h-full flex flex-col justify-between">
                <div
                  class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto"
                >
                  <template v-if="news?.date">
                    <p class="text-[1.8vh] font-bold leading-tight mb-0">
                      {{ getDay(news?.date) }}
                    </p>
                    <p class="text-[1vh] font-semibold">
                      {{ getMonth(news?.date) }}
                    </p>
                  </template>
                  <template v-else-if="news?.start_date || news?.end_date">
                    <p class="text-[1.8vh] font-bold leading-tight mb-0">
                      {{ getDateEvent(news?.start_date, news?.end_date) }}
                    </p>
                    <p class="text-[1vh] font-semibold">
                      {{ getMonthEvent(news?.start_date, news.end_date) }}
                    </p>
                  </template>
                </div>
                <a class="flex items-center whitespace-nowrap gap-1"
                  ><span class="text-[1.6vh] font-semibold">Leggi tutto</span
                  ><Icon icon="si:arrow-right-duotone" class="w-[4vw] h-[4vw]"
                /></a>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  reactive,
  watch,
  onMounted,
  withDefaults,
  defineProps,
  computed,
} from "vue";
import { Icon } from "@iconify/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, EffectFade } from "swiper/modules";
import { getDay, getMonth, getThinMonth } from "@/helpers/helpers";
import defaultPoiIcon from "@/assets/icons/map-poi.png";
import selectedPoiIcon from "@/assets/icons/map-selected-poi.png";
import userPositionIconUrl from "@/assets/icons/map-user-position.png";
import MediaLoader from '../MediaLoader.vue';

// Define props with default values
const props = withDefaults(
  defineProps<{
    newsList: Array<any>,
    play: boolean,
    delay: number,
    userPosition?: { lat: number, lng: number } | null  // Optional static marker position
  }>(),
  {
    newsList: [],
    play: true,
    delay: 5000,
    userPosition: null
  }
);

// Define emits
const emit = defineEmits(["selectedItem"]);

// Map settings
const zoom = ref(12);
const mapRef = ref(null);
const swiperRef = ref<SwiperType | null>(null);

// Hide controls and customize map appearance
const mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }]
    }
  ]
};

const mapCenter = ref({ lat: 45.4642, lng: 9.19 }); // Milan center coordinates
const activeMarkerIndex = ref(0);

const markers = computed(() => {
  return props.newsList.map((item, index) => ({
      position: {
        lat: item?.position?.latitude,
        lng: item?.position?.longitude,
      },
      isActive: index === activeMarkerIndex.value,
  }));
});

// Function to get marker dimensions based on desired height
function getMarkerDimensions(imageUrl: string, desiredHeight: number): Promise<{ width: number, height: number }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      resolve({
        width: Math.round(desiredHeight * aspectRatio),
        height: desiredHeight
      });
    };
    img.src = imageUrl;
  });
}

const defaultMarkerHeight = 60;
const activeMarkerHeight = defaultMarkerHeight * 1.5;
// Initialize marker dimensions
const defaultMarkerDimensions = ref({ width: 0, height: defaultMarkerHeight });
const activeMarkerDimensions = ref({ width: 0, height: activeMarkerHeight });
const userPositionDimensions = ref({ width: 0, height: defaultMarkerHeight });

// Update marker dimensions when component is mounted
onMounted(async () => {
  defaultMarkerDimensions.value = await getMarkerDimensions(defaultPoiIcon, defaultMarkerHeight);
  activeMarkerDimensions.value = await getMarkerDimensions(selectedPoiIcon, activeMarkerHeight);
  userPositionDimensions.value = await getMarkerDimensions(userPositionIconUrl, defaultMarkerHeight);
});

const defaultMarkerIcon = computed(() => ({
  url: defaultPoiIcon,
  size: defaultMarkerDimensions.value,
  scaledSize: defaultMarkerDimensions.value,
  anchor: { 
    x: defaultMarkerDimensions.value.width / 2, 
    y: defaultMarkerDimensions.value.height 
  },
}));

const activeMarkerIcon = computed(() => ({
  url: selectedPoiIcon,
  size: activeMarkerDimensions.value,
  scaledSize: activeMarkerDimensions.value,
  anchor: { 
    x: activeMarkerDimensions.value.width / 2, 
    y: activeMarkerDimensions.value.height 
  },
}));

const userPositionIcon = computed(() => ({
  url: userPositionIconUrl,
  size: userPositionDimensions.value,
  scaledSize: userPositionDimensions.value,
  anchor: { 
    x: userPositionDimensions.value.width / 2, 
    y: userPositionDimensions.value.height 
  },
}));

const boundsAdjusted = ref(false);

// Function to get map bounds including all markers
function getMarkerBounds(): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();
  
  // Add news markers to bounds
  markers.value.forEach((marker) => {
    bounds.extend(marker.position);
  });
  
  // Add static marker to bounds if it exists
  if (props.userPosition) {
    bounds.extend(props.userPosition);
  }
  
  return bounds;
}

const onTilesLoaded = () => {

  if (boundsAdjusted.value ) return;
  if (!mapRef.value) return;
  

  const mapRefVal: any = mapRef.value;

  try {
    // Access the map instance using $mapObject
    const gmap = mapRefVal.$mapObject;
    if (!gmap) {
      return;
    }

    // Get map dimensions
    const mapDiv = gmap.getDiv();
    const mapHeight = mapDiv.clientHeight;
    const bottomPadding = Math.floor(mapHeight * 0.25); 

    const padding = {
      top: 50,      
      right: 5,    
      bottom: bottomPadding , 
      left: 5      
    };

    const bounds = getMarkerBounds();
    gmap.fitBounds(bounds, padding);
    boundsAdjusted.value = true;
  } catch (error) {
    console.error(">>> Error setting bounds:", error);
  }
};

// Reset the bounds adjusted flag when markers change
watch(() => markers.value, () => {
  boundsAdjusted.value = false;
}, { deep: true });

// Also handle the map loaded event
const onMapLoaded = () => {
  onTilesLoaded();
};

// onMounted(() => {
//   console.log(">>> Component mounted, map reference:", mapRef.value);
// });

function onSwiper(swiper: SwiperType) {
  swiperRef.value = swiper;
  if (props.play) {
    swiper.autoplay.start();
  } else {
    swiper.autoplay.stop();
  }
}

function selectedItem(item: any) {
    emit("selectedItem", item);
}

function handleMarkerClick(index: number) {
  activeMarkerIndex.value = index;
  if (swiperRef.value) {
    swiperRef.value.slideToLoop(index);
  }
}

watch(() => props.play, (newVal) => {
  console.log("Play prop changed:", newVal);
  if (swiperRef.value) {
    if (newVal) {
      console.log("Starting autoplay");
      swiperRef.value.autoplay.start();
    } else {
      console.log("Stopping autoplay");
      swiperRef.value.autoplay.stop();
    }
  }
}, { immediate: true }); 



function onSlideChangeStart() {
  console.log("Slide change started");
  updateActiveMarker();
}

function updateActiveMarker() {
  if (swiperRef.value) {
    const swiper = swiperRef.value;
    const realIndex = swiper.realIndex;
    
    if (activeMarkerIndex.value !== realIndex) {
      activeMarkerIndex.value = realIndex;
    }
  }
}

function getDateEvent(start: string, end: string): string {
  if (start && end) {
    return `${getDay(start)}-${getDay(end)}`;
  }

  return start ? getDay(start) : end ? getDay(end) : "";
}

function getMonthEvent(start: string, end: string): string {
  if (start || end) {
    const initMonth = start ? getMonth(start) : "";
    const endMonth = end ? getMonth(end) : "";

    if (initMonth && endMonth) {
      return initMonth === endMonth
        ? initMonth
        : `${getThinMonth(start)}-${getThinMonth(end)}`;
    }

    return initMonth || endMonth;
  }
  return "";
}

const touchStartX = ref(0);

function onTouchStart(swiper: any) {
  touchStartX.value = swiper.touches.startX;
}

function onTouchEnd(swiper: any) {
  const touchEndX = swiper.touches.currentX;
  const diff = touchEndX - touchStartX.value;
  
  // If it was a significant swipe
  if (Math.abs(diff) > 50) {
    console.log("Touch ended, updating marker");
    updateActiveMarker();
  }
}
</script>

<style scoped>
.custom-fullscreen-btn {
  z-index: 500;
  cursor: pointer;
}

.gmap-map {
  border-radius: 10px;
  overflow: hidden;
}
</style>
