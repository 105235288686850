import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-neutral-100 h-[49vw] relative" }
const _hoisted_2 = { class: "absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35" }
const _hoisted_3 = { class: "border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center ms-auto" }
const _hoisted_4 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_5 = { class: "text-[1vh] font-semibold" }
const _hoisted_6 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_7 = { class: "text-[1vh] font-semibold" }
const _hoisted_8 = { class: "leading-tight mt-auto" }
const _hoisted_9 = { class: "text-[2.2vh] font-bold" }
const _hoisted_10 = { class: "text-[1.2vh] font-semibold mt-[1vw]" }
const _hoisted_11 = { class: "absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex gap-5 items-center bg-neutral-800/35" }
const _hoisted_12 = { class: "flex-1 leading-tight" }
const _hoisted_13 = { class: "text-[2.2vh] font-bold" }
const _hoisted_14 = { class: "text-[1.2vh] font-semibold mt-[1vw]" }
const _hoisted_15 = {
  key: 0,
  class: "flex items-center whitespace-nowrap gap-1 mt-[2vw]"
}
const _hoisted_16 = { class: "border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto" }
const _hoisted_17 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_18 = { class: "text-[1vh] font-semibold" }
const _hoisted_19 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_20 = { class: "text-[1vh] font-semibold" }
const _hoisted_21 = {
  key: 0,
  class: "absolute w-full h-full px-[4.5vw] pt-[3.8vw] pb-[2.2vw] text-white top-0 right-0 flex items-center bg-neutral-800/35"
}
const _hoisted_22 = { class: "w-full leading-tight" }
const _hoisted_23 = { class: "text-[2.2vh] font-bold" }
const _hoisted_24 = { class: "text-[1.2vh] font-semibold mt-[1vw]" }
const _hoisted_25 = { class: "h-full flex flex-col justify-between" }
const _hoisted_26 = { class: "border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto" }
const _hoisted_27 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_28 = { class: "text-[1vh] font-semibold" }
const _hoisted_29 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_30 = { class: "text-[1vh] font-semibold" }
const _hoisted_31 = {
  key: 0,
  class: "flex items-center whitespace-nowrap gap-1"
}
const _hoisted_32 = {
  key: 1,
  class: "absolute w-full h-full p-[8vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
}
const _hoisted_33 = { class: "absolute top-[8vw] right-[8vw] border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto" }
const _hoisted_34 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_35 = { class: "text-[1vh] font-semibold" }
const _hoisted_36 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_37 = { class: "text-[1vh] font-semibold" }
const _hoisted_38 = { class: "leading-tight pt-[17vw]" }
const _hoisted_39 = { class: "text-[3.8vh] font-bold" }
const _hoisted_40 = { class: "text-[1.8vh] font-semibold mt-[2vw]" }
const _hoisted_41 = {
  key: 0,
  class: "flex items-center gap-1 mt-[5vw]"
}

import { Icon } from "@iconify/vue";
import { getDay, getMonth, getThinMonth } from "@/helpers/helpers";

import MediaLoader from '@/components/MediaLoader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsEventCard',
  props: {
    news: { default: {} },
    mode: { default: "list" },
    showReadMore: { type: Boolean, default: false }
  },
  emits: ["click"],
  setup(__props: any) {

const props = __props;



function getDateEvent(start: string, end: string): string {
  if (start && end) {
    return `${getDay(start)}-${getDay(end)}`;
  }
  return start ? getDay(start) : end ? getDay(end) : "";
}

function getMonthEvent(start: string, end: string): string {
  if (start || end) {
    const initMonth = start ? getMonth(start) : "";
    const endMonth = end ? getMonth(end) : "";

    if (initMonth && endMonth) {
      return initMonth === endMonth
        ? initMonth
        : `${getThinMonth(start)}-${getThinMonth(end)}`;
    }
    return initMonth || endMonth;
  }
  return "";
}

return (_ctx: any,_cache: any) => {
  return (_ctx.mode === 'grid')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "bg-neutral-100 aspect-square relative",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', _ctx.news)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(MediaLoader, {
            src: _ctx.news?.image ? _ctx.news.image[0]?.url : 'https://picsum.photos/200/300',
            type: "image",
            alt: _ctx.news?.title,
            class: "w-full h-full object-cover"
          }, null, 8, ["src", "alt"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.news?.date)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(getDay)(_ctx.news?.date)), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(getMonth)(_ctx.news?.date)), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(getDateEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(getMonthEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1)
                  ], 64))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.news?.title), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.news?.abstract), 1)
            ])
          ])
        ])
      ]))
    : (_ctx.mode === 'list' || !_ctx.mode)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "bg-neutral-100 h-[25vw] relative",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click', _ctx.news)))
        }, [
          _createVNode(MediaLoader, {
            src: _ctx.news?.image ? _ctx.news.image[0]?.url : 'https://picsum.photos/200/300',
            type: "image",
            alt: _ctx.news?.title,
            class: "w-full h-full object-cover"
          }, null, 8, ["src", "alt"]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.news?.title), 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.news?.abstract), 1),
              (_ctx.showReadMore)
                ? (_openBlock(), _createElementBlock("a", _hoisted_15, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-[1.6vh] font-semibold" }, "Leggi tutto", -1)),
                    _createVNode(_unref(Icon), {
                      icon: "si:arrow-right-duotone",
                      class: "w-[4vw] h-[4vw]"
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, [
              (_ctx.news?.date)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(getDay)(_ctx.news?.date)), 1),
                    _createElementVNode("p", _hoisted_18, _toDisplayString(_unref(getMonth)(_ctx.news?.date)), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("p", _hoisted_19, _toDisplayString(getDateEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1),
                    _createElementVNode("p", _hoisted_20, _toDisplayString(getMonthEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1)
                  ], 64))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "w-full h-full min-w-full bg-neutral-100 relative",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click', _ctx.news)))
        }, [
          _createVNode(MediaLoader, {
            src: _ctx.news?.image ? _ctx.news.image[0]?.url : 'https://picsum.photos/200/300',
            type: "image",
            alt: _ctx.news?.title,
            class: "w-full h-full object-cover"
          }, null, 8, ["src", "alt"]),
          (_ctx.mode === 'slim-slide')
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("h3", _hoisted_23, _toDisplayString(_ctx.news?.title), 1),
                  _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.news?.abstract), 1)
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    (_ctx.news?.date)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(getDay)(_ctx.news?.date)), 1),
                          _createElementVNode("p", _hoisted_28, _toDisplayString(_unref(getMonth)(_ctx.news?.date)), 1)
                        ], 64))
                      : (_ctx.news?.start_date || _ctx.news?.end_date)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createElementVNode("p", _hoisted_29, _toDisplayString(getDateEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1),
                            _createElementVNode("p", _hoisted_30, _toDisplayString(getMonthEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1)
                          ], 64))
                        : _createCommentVNode("", true)
                  ]),
                  (_ctx.showReadMore)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_31, [
                        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-[1.6vh] font-semibold" }, "Leggi tutto", -1)),
                        _createVNode(_unref(Icon), {
                          icon: "si:arrow-right-duotone",
                          class: "w-[4vw] h-[4vw]"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  (_ctx.news?.date)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("p", _hoisted_34, _toDisplayString(_unref(getDay)(_ctx.news?.date)), 1),
                        _createElementVNode("p", _hoisted_35, _toDisplayString(_unref(getMonth)(_ctx.news?.date)), 1)
                      ], 64))
                    : (_ctx.news?.start_date || _ctx.news?.end_date)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("p", _hoisted_36, _toDisplayString(getDateEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1),
                          _createElementVNode("p", _hoisted_37, _toDisplayString(getMonthEvent(_ctx.news?.start_date, _ctx.news?.end_date)), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("h3", _hoisted_39, _toDisplayString(_ctx.news?.title), 1),
                  _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.news?.abstract), 1),
                  (_ctx.showReadMore)
                    ? (_openBlock(), _createElementBlock("a", _hoisted_41, [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-[1.6vh] font-semibold" }, "Leggi tutto", -1)),
                        _createVNode(_unref(Icon), {
                          icon: "si:arrow-right-duotone",
                          class: "w-[4vw] h-[4vw]"
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
        ]))
}
}

})