import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/qr-tel.png'
import _imports_1 from '@/assets/images/play-icon.svg'


const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = { class: "flex flex-col h-full" }
const _hoisted_3 = { class: "bg-neutral-100 h-[56vw] relative" }
const _hoisted_4 = { class: "absolute w-full h-full p-[6vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35" }
const _hoisted_5 = { class: "border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto" }
const _hoisted_6 = { class: "text-[2.2vh] font-bold leading-tight mb-0" }
const _hoisted_7 = { class: "text-[1.4vh] font-semibold" }
const _hoisted_8 = { class: "text-[2.2vh] font-bold leading-tight mb-0" }
const _hoisted_9 = { class: "text-[1.4vh] font-semibold" }
const _hoisted_10 = { class: "text-[3.8vh] font-bold leading-tight mt-auto" }
const _hoisted_11 = { class: "h-full relative" }
const _hoisted_12 = { class: "h-full overflow-y-auto scrollbar-none" }
const _hoisted_13 = { class: "gradient-gray p-[6vw] pb-[12vw] text-gray-theme text-[1.8vh] font-semibold" }
const _hoisted_14 = { class: "rounded-t-3xl mt-[-4vh] bg-white pt-[6vw] pb-[35vh] shadow-top" }
const _hoisted_15 = { class: "ps-[6vw] pe-[8vw] pt-[2vw] pb-[2vw]" }
const _hoisted_16 = { class: "relative gradient-gray rounded-3xl p-[4vw] pe-[21vw] text-gray-theme flex gap-[4vw] items-center" }
const _hoisted_17 = { class: "absolute top-[-2vw] right-[-2vw] rounded-xl shadow-center bg-white p-[2vw]" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "prose serif p-[6vw]" }
const _hoisted_20 = { class: "text-[1.6vh] leading-relaxed space-y-6" }
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = { key: 2 }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["src", "controls", "onPlay", "onPause", "onEnded"]
const _hoisted_25 = { class: "text-[2.0vh] font-bold leading-tight mb-[2vw] uppercase pl-[6vw] opacity-80" }
const _hoisted_26 = { class: "flex flex-col gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none" }

import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue";
import { Icon } from "@iconify/vue";
import MediaLoader from './MediaLoader.vue';
import MapComponent from "@/components/MapComponent.vue";
import PdfViewerComponent from "./PdfViewerComponent.vue";
import GalleryComponent from "./GalleryComponent.vue";
import NewsEventCard from "@/components/news-event/NewsEventCard.vue"; 
import { getDay, getMonth } from "@/helpers/helpers";
import { useLayoutStore } from "@/stores/layoutStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsEventDetail',
  props: {
    data: {}
  },
  emits: ['go-back', 'open-correlated'],
  setup(__props: any, { emit: __emit }) {

const layoutStore = useLayoutStore();

const emit = __emit;

const props = __props;

//aggiungere tutte le condizioni in or 
const isMapFullScreen = ref(false);
const galleryState = ref({
  type: null as "image" | "video" | null,
});
const showPdfViewer = ref(false);
const currentPdfUrl = ref("");
const videoStates = ref([]);
const videoRefs = ref<HTMLVideoElement[]>([]);

const openPdfViewer = (url: string) => {
  console.log("openPDF clicked", url);
  currentPdfUrl.value = url;
  showPdfViewer.value = true;
};

const isLayoutBusy = computed(
  () =>
    isMapFullScreen.value ||
    galleryState.value.type !== null ||
    showPdfViewer.value
);

const images = computed(() => {
  return props.data?.gallery?.map((image: any) => image.url) || [];
});

const videos = computed(() => {
  if (!props.data?.videos) return [];
  // Initialize video states if needed
  if (videoStates.value.length !== props.data.videos.length) {
    videoStates.value = props.data.videos.map(() => false);
  }
  return props.data.videos.map((video, index) => ({
    ...video,
    index,
  }));
});

const updatePlayingState = (index: number, isPlaying: boolean) => {
  videoStates.value[index] = isPlaying;
};

const mapEnterFullScreen = () => {
  isMapFullScreen.value = true;
};

const mapExitFullScreen = () => {
  isMapFullScreen.value = false;
};

const setGalleryState = (type: "image" | "video") => {
  galleryState.value.type = type;
};

const resetGalleryState = () => {
  galleryState.value.type = null;
};

const handleGoBack = () => {
  console.log("going back");
  if (!!isMapFullScreen.value) mapExitFullScreen();
  else if (galleryState.value.type !== null) resetGalleryState();
  else if (!!showPdfViewer.value) {
    showPdfViewer.value = false;
    currentPdfUrl.value = "";
  } 
  else {
    emit('go-back');
  }
};

const playVideo = (index: number) => {
  if (videoRefs.value[index]) {
    videoRefs.value[index].play();
  }
};

const fetchCorrelatedContent = () => {
  if (!('correlated' in props.data)) {
    layoutStore.fetchCorrelated(props.data.type, props.data.id)
      .then(result => {
        console.log('Correlated content:', result);
        if (result && 'correlated' in result) {
          console.log("Correlated content2:", result);
          props.data.correlated = result.correlated;
        }
      })
      . catch(error => console.error('Error fetching correlated content:', error));
  }
};

watch(() => props.data, fetchCorrelatedContent, { immediate: true });

onMounted(() => {
  window.addEventListener("navigation:goBack", handleGoBack);
});

onBeforeUnmount(() => {
  window.removeEventListener("navigation:goBack", handleGoBack);
});

function openCorrelated(item: any) {
    console.log(item);
    emit('open-correlated', item);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.image, (img, index) => {
          return (_openBlock(), _createBlock(MediaLoader, {
            key: index,
            type: "image",
            src: img?.url ? img.url : '',
            class: "w-full h-full object-cover"
          }, null, 8, ["src"]))
        }), 128)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.data?.date)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(getDay)(_ctx.data?.date)), 1),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(getMonth)(_ctx.data?.date)), 1)
                ], 64))
              : (_ctx.data?.start_date && _ctx.data?.end_date)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.data?.start_date ? _unref(getDay)(_ctx.data?.start_date) : "") + " - " + _toDisplayString(_ctx.data?.end_date ? _unref(getDay)(_ctx.data?.end_date) : ""), 1),
                    _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.data?.start_date ? _unref(getMonth)(_ctx.data?.start_date) : ""), 1)
                  ], 64))
                : _createCommentVNode("", true)
          ]),
          _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.data?.title), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-full h-[1.2vh] absolute z-10 top-0 left-0 bg-gradient-to-b from-black/20 to-transparent" }, null, -1)),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.data?.abstract), 1),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _cache[1] || (_cache[1] = _createElementVNode("img", {
                  src: _imports_0,
                  class: "w-[10vw]"
                }, null, -1)),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "leading-tight" }, [
                  _createElementVNode("h4", { class: "text-[2.8vh] font-bold" }, "Portami con te!"),
                  _createElementVNode("p", { class: "text-[1.8vh] font-semibold mt-1" }, " Scansiona il codice QR e leggi questa pagina sul tuo smartphone ")
                ], -1)),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("img", {
                    src: 'data:image/png;base64,' + _ctx.data?.qr_code,
                    alt: "QR Code",
                    class: "w-[16vw]"
                  }, null, 8, _hoisted_18)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("p", {
                  innerHTML: _ctx.data?.body
                }, null, 8, _hoisted_21)
              ])
            ]),
            (_ctx.data?.position)
              ? (_openBlock(), _createBlock(MapComponent, {
                  key: 0,
                  latitude: _ctx.data.position?.latitude,
                  address: _ctx.data.position?.address,
                  longitude: _ctx.data.position?.longitude,
                  fullScreen: isMapFullScreen.value,
                  onMapEnterFullScreen: mapEnterFullScreen,
                  onMapExitFullScreen: mapExitFullScreen
                }, null, 8, ["latitude", "address", "longitude", "fullScreen"]))
              : _createCommentVNode("", true),
            (images.value?.length > 0)
              ? (_openBlock(), _createBlock(GalleryComponent, {
                  key: 1,
                  contents: images.value,
                  fullScreen: galleryState.value.type === 'image',
                  onGalleryEnterFullScreen: _cache[0] || (_cache[0] = () => setGalleryState('image')),
                  onGalleryExitFullScreen: resetGalleryState,
                  type: 'image'
                }, null, 8, ["contents", "fullScreen"]))
              : _createCommentVNode("", true),
            (videos.value?.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(videos.value, (media, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "relative my-3 flex justify-center bg-black"
                    }, [
                      _cache[4] || (_cache[4] = _createTextVNode(" > ")),
                      (!videoStates.value[index])
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "w-full h-full absolute top-0 left-0 cursor-pointer z-20",
                            onClick: ($event: any) => (playVideo(index))
                          }, _cache[3] || (_cache[3] = [
                            _createElementVNode("img", {
                              src: _imports_1,
                              class: "absolute inset-0 m-auto w-20 h-20 pointer-events-none"
                            }, null, -1)
                          ]), 8, _hoisted_23))
                        : _createCommentVNode("", true),
                      _createElementVNode("video", {
                        ref_for: true,
                        ref: 
                      (el) => {
                        if (el) videoRefs.value[index] = el;
                      }
                    ,
                        src: media.url,
                        class: "h-[56vw]",
                        controls: videoStates.value[index],
                        controlsList: "nodownload nofullscreen noremoteplayback noplaybackrate",
                        disablePictureInPicture: "",
                        preload: "auto",
                        onPlay: ($event: any) => (updatePlayingState(index, true)),
                        onPause: ($event: any) => (updatePlayingState(index, false)),
                        onEnded: ($event: any) => (updatePlayingState(index, false))
                      }, null, 40, _hoisted_24)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.data?.correlated?.length > 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.data?.correlated, (group, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "mb-[5vw]"
                  }, [
                    _createElementVNode("h3", _hoisted_25, _toDisplayString(group?.title), 1),
                    _createElementVNode("div", _hoisted_26, [
                      _createTextVNode(_toDisplayString(_ctx.data?.details) + " ", 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group?.contentList, (item, index) => {
                        return (_openBlock(), _createBlock(NewsEventCard, {
                          key: index,
                          news: item.relation,
                          mode: "list",
                          "show-read-more": true,
                          onClick: openCorrelated
                        }, null, 8, ["news"]))
                      }), 128))
                    ])
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}
}

})