import { vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 3,
  class: "loading-indicator"
}
const _hoisted_4 = {
  key: 4,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["media-loader", { 'is-loading': _ctx.loading }])
  }, [
    (_ctx.mediaType === 'image')
      ? _withDirectives((_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.mediaUrl,
          alt: _ctx.alt,
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLoad && _ctx.handleLoad(...args))),
          onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleError && _ctx.handleError(...args))),
          class: "media-content"
        }, null, 40, _hoisted_1)), [
          [_vShow, !_ctx.loading],
          [_directive_lazy, _ctx.mediaUrl]
        ])
      : (_ctx.mediaType === 'video')
        ? _withDirectives((_openBlock(), _createElementBlock("video", {
            key: 1,
            src: _ctx.mediaUrl,
            controls: "",
            onLoadeddata: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleLoad && _ctx.handleLoad(...args))),
            onError: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleError && _ctx.handleError(...args))),
            class: "media-content"
          }, null, 40, _hoisted_2)), [
            [_vShow, !_ctx.loading]
          ])
        : (_ctx.mediaType === 'map')
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              ref: "mapContainer",
              class: "map-container media-content",
              style: _normalizeStyle({ height: _ctx.mapHeight })
            }, null, 4))
          : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[5] || (_cache[5] = [
          _createElementVNode("div", { class: "spinner" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.error) + " ", 1),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.retry && _ctx.retry(...args))),
            class: "retry-button"
          }, "Retry")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}