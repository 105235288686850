import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/play-icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-4 gap-[1.5vw] pb-[5vh]"
}
const _hoisted_2 = {
  key: 0,
  class: "relative"
}
const _hoisted_3 = {
  key: 1,
  class: "full-screen"
}
const _hoisted_4 = {
  key: 0,
  class: "relative"
}

import { ref, computed, watch, onUnmounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import MediaLoader from './MediaLoader.vue';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryComponent',
  props: {
    contents: {},
    fullScreen: { type: Boolean, default: false },
    type: { default: 'image' }
  },
  emits: ["galleryEnterFullScreen", "galleryExitFullScreen"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

// State and methods
const isFullScreen = computed(() => props.fullScreen);
const currentIndex = ref(0);
const thumbsSwiper:any = ref(null);

const openGallery = (index: number) => {
  currentIndex.value = index;
  emit("galleryEnterFullScreen");
};

const closeGallery = () => {
  emit("galleryExitFullScreen");
};

// Handle reinitialization of Swiper on entering full-screen
watch(isFullScreen, (newValue) => {
  if (!newValue) {
    destroySwiper();
  } else {
    reinitializeSwiper();
  }
});

// Function to reinitialize Swiper
const reinitializeSwiper = () => {
  thumbsSwiper.value = null;
  // Delay to allow for DOM to be reset
  setTimeout(() => {
    thumbsSwiper.value = thumbsSwiper.value;
  }, 0);
};

// Function to destroy Swiper instance
const destroySwiper = () => {
  if (thumbsSwiper.value) {
    thumbsSwiper.value.destroy();
    thumbsSwiper.value = null;
  }
};

onUnmounted(() => {
  destroySwiper();
});

// Helper to set the thumbs swiper reference
const setThumbsSwiper = (swiper: any) => {
  thumbsSwiper.value = swiper;
};

const modules = [FreeMode, Navigation, Thumbs];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!isFullScreen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (media, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              (_ctx.type === 'video')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _cache[0] || (_cache[0] = _createElementVNode("img", {
                      src: _imports_0,
                      class: "absolute inset-0 m-auto w-12 h-12 z-10"
                    }, null, -1)),
                    _createVNode(MediaLoader, {
                      type: "video",
                      src: media,
                      class: "w-full aspect-square cursor-pointer object-cover",
                      onClick: ($event: any) => (openGallery(i)),
                      controls: false,
                      autoplay: false,
                      muted: true
                    }, null, 8, ["src", "onClick"])
                  ]))
                : (_openBlock(), _createBlock(MediaLoader, {
                    key: 1,
                    type: "image",
                    src: media,
                    class: "w-full aspect-square cursor-pointer object-cover",
                    onClick: ($event: any) => (openGallery(i))
                  }, null, 8, ["src", "onClick"]))
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (isFullScreen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_unref(Swiper), {
            style: {
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      },
            spaceBetween: 10,
            navigation: true,
            "initial-slide": currentIndex.value,
            thumbs: { swiper: thumbsSwiper.value },
            modules: modules,
            class: "mySwiper2"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (media, i) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), { key: i }, {
                  default: _withCtx(() => [
                    (_ctx.type === 'video')
                      ? (_openBlock(), _createBlock(MediaLoader, {
                          key: 0,
                          type: "video",
                          src: media,
                          class: "object-contain w-full h-full",
                          controls: true,
                          autoplay: true
                        }, null, 8, ["src"]))
                      : (_openBlock(), _createBlock(MediaLoader, {
                          key: 1,
                          type: "image",
                          src: media,
                          class: "object-contain w-full h-full"
                        }, null, 8, ["src"]))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["initial-slide", "thumbs"]),
          _createVNode(_unref(Swiper), {
            onSwiper: setThumbsSwiper,
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            modules: modules,
            class: "mySwiper"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contents, (media, i) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                  key: 'thumb' + i
                }, {
                  default: _withCtx(() => [
                    (_ctx.type === 'video')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _cache[1] || (_cache[1] = _createElementVNode("img", {
                            src: _imports_0,
                            class: "absolute inset-0 m-auto w-12 h-12 z-10"
                          }, null, -1)),
                          _createVNode(MediaLoader, {
                            type: "video",
                            src: media,
                            class: "object-cover w-full aspect-square",
                            controls: false,
                            autoplay: false,
                            muted: true
                          }, null, 8, ["src"])
                        ]))
                      : (_openBlock(), _createBlock(MediaLoader, {
                          key: 1,
                          type: "image",
                          src: media,
                          class: "object-cover w-full aspect-square"
                        }, null, 8, ["src"]))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})