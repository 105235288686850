import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './assets/main.scss';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueLazyload from 'vue-lazyload';

const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCMQe6lO-x3pMPVNe9OqOsZxbytUfKO1JA',
        libraries: 'places',
        region: 'IT',
        language: 'it'
    }
});

// Configure Vue Lazyload
app.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 2,
    lazyComponent: true,
    observer: true,
    throttleWait: 500
});

app.mount('#app');
