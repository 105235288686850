import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
console.log('app akey', process.env);
export const echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  wsHost: process.env.VUE_APP_PUSHER_HOST || window.location.hostname,
  wsPort: process.env.VUE_APP_PUSHER_PORT ? parseInt(process.env.VUE_APP_PUSHER_PORT) : 6001,
  forceTLS: process.env.NODE_ENV === 'production' || (process.env.VUE_APP_PUSHER_SCHEME ?? 'https') === 'https',
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  encrypted: process.env.NODE_ENV === 'production'
});
