// stores/settings.ts (Pinia store for global settings)
import { defineStore } from "pinia";
import axios from "axios";
import AxiosClient from "@/Axios/AxiosClient";

// Define the shape of the settings state
export interface SettingsState {
  id: number | null;
  touchEnabled: boolean;
  isActive: boolean;
  layoutType: LayoutType;
  standByActive: boolean;
  standbyTime: number | null;
  scrollDuration: number | null;
  standByTransition: string | null;
  standbyMedia: string[] | null;
  isLoading: boolean;
  error: string | null;
  logo: string | null;
  scenarioName: string;
  position: any
}

export enum LayoutType {
  HEADER_BODY_FOOTER = "HEADER_BODY_FOOTER",
  BODY_FOOTER = "BODY_FOOTER",
  HEADER_BODY = "HEADER_BODY",
  FULL_SCREEN = "FULL_SCREEN",
}
export interface StandbyOptions {
  type: string;
  timer: number;
  transition: string;
  scroll_duration: number;
  media: string[];
}
export interface SettingsResponse {
  id: number;
  is_active: boolean;
  is_touch: boolean;
  layout_type: LayoutType;
  is_standby: boolean;
  standby_options?: StandbyOptions | null; // Optional in case standby is disabled
  logo: string | null;
  scenario_name: string | null;
  position: object | null;
}

export const useSettingsStore = defineStore("settings", {
  state: (): SettingsState => ({
    id: null,
    touchEnabled: false,
    isActive: false,
    layoutType: LayoutType.HEADER_BODY_FOOTER, // Default layout type
    standByActive: true,
    standbyTime: null,
    scrollDuration: null,
    standByTransition: null,
    standbyMedia: null,
    isLoading: true, // Loading state for configurations
    error: null, // Error handling
    logo: require("@/assets/images/stemma-demo.png"),
    scenarioName: "Totem",
    position: null
  }),

  getters: {
    standbyTimeout(): number | null {
      return this.standbyTime ?? null;
    },
  },
  actions: {
    // Action to fetch configuration from the backend
    async fetchConfiguration() {
      this.isLoading = true;
      console.log("Fetching settings...");
      try {
        const response = await AxiosClient.get<SettingsResponse>(
          "/totem/config"
        );

        const config = response.data;
        console.log("Configuration:", config, config.is_touch);
        // Update the state with the fetched configuration
        this.touchEnabled = config.is_touch;
        this.isActive = config.is_active;
        this.layoutType = config.layout_type;
        this.standByActive = config.is_standby;
        this.id = config.id;
        this.logo = config.logo ?? require("@/assets/images/stemma-demo.png");
        this.scenarioName = config.scenario_name ?? "Totem";
        this.position = config.position

        // Handle touch-specific settings
        if (this.touchEnabled && this.standByActive) {
          this.standbyTime =
            config.standby_options && config.standby_options.timer
              ? config.standby_options.timer * 1000
              : 20000;
          this.standByTransition =
            config.standby_options?.transition == "Scorrimento"
              ? "scroll"
              : "fade";
          this.standbyMedia = config.standby_options?.media ?? null;
          this.scrollDuration =
            config.standby_options && config.standby_options.scroll_duration
              ? config.standby_options?.scroll_duration * 1000
              : 3000;

          // If there is a standby background, cache it
          //   if (this.standbyBackground) {
          //     console.log("Caching standby background:", this.standbyBackground);
          //     await this.cacheStandbyBackground(this.standbyBackground);
          //   }
        }
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching configuration:", error);
        this.error = "Failed to fetch configuration.";
      } finally {
        this.isLoading = false;
      }
    },

    // Function to cache the standby image or video
    async cacheStandbyBackground(url: string) {
      try {
        const response = await axios.get(url, {
          responseType: "blob",
        });
        const cache = await caches.open("totem-standby-cache");
        const responseClone = new Response(response.data as BodyInit | null);
        await cache.put(url, responseClone);
        console.log("Finished Caching standby background:", url);
      } catch (error) {
        console.error("Error caching standby background:", error);
        this.error = "Failed to cache standby background.";
      }
    },
  },
});
