<template>
  <div class="media-loader" :class="{ 'is-loading': loading }">
    <!-- Image -->
    <img
      v-if="mediaType === 'image'"
      v-show="!loading"
      :src="mediaUrl"
      :alt="alt"
      @load="handleLoad"
      @error="handleError"
      class="media-content"
      v-lazy="mediaUrl"
    />

    <!-- Video -->
    <video
      v-else-if="mediaType === 'video'"
      v-show="!loading"
      :src="mediaUrl"
      controls
      @loadeddata="handleLoad"
      @error="handleError"
      class="media-content"
    ></video>

    <!-- Google Map -->
    <div
      v-else-if="mediaType === 'map'"
      ref="mapContainer"
      class="map-container media-content"
      :style="{ height: mapHeight }"
    ></div>

    <!-- Loading State -->
    <div v-if="loading" class="loading-indicator">
      <div class="spinner"></div>
    </div>

    <!-- Error State -->
    <div v-if="error" class="error-message">
      {{ error }}
      <button @click="retry" class="retry-button">Retry</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue';
import { saveMedia, getMedia } from '@/utils/mediaDb';
import type { Loader } from '@googlemaps/js-api-loader';

declare global {
  interface Window {
    google: any;
  }
}

export default defineComponent({
  name: 'MediaLoader',
  
  props: {
    src: {
      type: String,
      required: true
    },
    type: {
      type: String as () => 'image' | 'video' | 'map',
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    mapOptions: {
      type: Object,
      default: () => ({
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8
      })
    },
    mapHeight: {
      type: String,
      default: '400px'
    }
  },

  setup(props) {
    const mediaUrl = ref<string>(props.src);
    const loading = ref(true);
    const error = ref<string | null>(null);
    const mediaType = ref(props.type);
    const mapContainer = ref<HTMLElement | null>(null);
    let map: google.maps.Map | null = null;

    const loadMedia = async () => {
      try {
        loading.value = true;
        error.value = null;

        // Try to get from cache first
        const cachedMedia = await getMedia(props.src);
        
        if (cachedMedia) {
          console.log("Looikng for media in cache", props.src, cachedMedia);
          if (typeof cachedMedia.data === 'string') {
            mediaUrl.value = cachedMedia.data;
          } else {
            mediaUrl.value = URL.createObjectURL(cachedMedia.data as Blob);
          }
          loading.value = false;
          return;
        }

        // If not in cache, fetch and cache
        if (mediaType.value === 'map') {
          await initializeMap();
        } else {
          const response = await fetch(props.src);
          if (!response.ok) throw new Error('Failed to fetch media');

          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          mediaUrl.value = url;

          // Cache the media
          await saveMedia(props.src, blob, mediaType.value);
        }

        loading.value = false;
      } catch (err) {
        error.value = 'Failed to load media';
        loading.value = false;
        console.error('Media loading error:', err);
      }
    };

    const initializeMap = async () => {
      if (!mapContainer.value || !window.google?.maps) return;

      try {
        map = new window.google.maps.Map(mapContainer.value, {
          ...props.mapOptions,
          // Disable features that might not work offline
          gestureHandling: navigator.onLine ? 'auto' : 'none',
        });
      } catch (err) {
        error.value = 'Failed to load map';
        console.error('Map initialization error:', err);
      }
    };

    const handleLoad = () => {
      loading.value = false;
      error.value = null;
    };

    const handleError = () => {
      error.value = 'Failed to load media';
      loading.value = false;
    };

    const retry = () => {
      loadMedia();
    };

    // Watch for online/offline status
    watch(() => navigator.onLine, (isOnline) => {
      if (isOnline && error.value) {
        retry();
      }
    });

    // Watch for src changes
    watch(() => props.src, () => {
      loadMedia();
    });

    onMounted(() => {
      loadMedia();
    });

    return {
      mediaUrl,
      loading,
      error,
      mediaType,
      mapContainer,
      handleLoad,
      handleError,
      retry
    };
  }
});
</script>

<style scoped>
.media-loader {
  position: relative;
  width: 100%;
  min-height: 100px;
}

.media-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #e74c3c;
}

.retry-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.map-container {
  width: 100%;
  min-height: 200px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
