import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
    ? `${process.env.VUE_APP_BASE_URL}/api/`
    : "http://api.totem.localhost/api/", // Base URL for API
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to retrieve the token
const getToken = (): string => {
  if (process.env.VUE_APP_ENV === "local") {
    return process.env.VUE_APP_API_TOKEN || "";
  }
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get("token") || null;
  if (token) {
    localStorage.setItem("authToken", token); // Save token in local storage for later use
    return token;
  } else {
    // Retrieve token from local storage if not in URL
    return localStorage.getItem("authToken") || "";
  }
};

// Interceptor to add the token to each request
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken(); // Use getToken function to retrieve the token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
