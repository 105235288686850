<template>
  <!-- Grid Mode -->
  <div
    v-if="mode === 'grid'"
    class="bg-neutral-100 aspect-square relative"
    @click="$emit('click', news)"
  >
    <div class="bg-neutral-100 h-[49vw] relative">
      <MediaLoader
        :src="news?.image ? news.image[0]?.url : 'https://picsum.photos/200/300'"
        type="image"
        :alt="news?.title"
        class="w-full h-full object-cover"
      />
      <div
        class="absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
      >
        <div
          class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center ms-auto"
        >
          <template v-if="news?.date">
            <p class="text-[1.8vh] font-bold leading-tight mb-0">
              {{ getDay(news?.date) }}
            </p>
            <p class="text-[1vh] font-semibold">{{ getMonth(news?.date) }}</p>
          </template>
          <template v-else>
            <p class="text-[1.8vh] font-bold leading-tight mb-0">
              {{ getDateEvent(news?.start_date, news?.end_date) }}
            </p>
            <p class="text-[1vh] font-semibold">
              {{ getMonthEvent(news?.start_date, news?.end_date) }}
            </p>
          </template>
        </div>
        <div class="leading-tight mt-auto">
          <h3 class="text-[2.2vh] font-bold">{{ news?.title }}</h3>
          <p class="text-[1.2vh] font-semibold mt-[1vw]">
            {{ news?.abstract }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- List Mode (Default) -->
  <div
    v-else-if="mode === 'list' || !mode"
    class="bg-neutral-100 h-[25vw] relative"
    @click="$emit('click', news)"
  >
    <MediaLoader
      :src="news?.image ? news.image[0]?.url : 'https://picsum.photos/200/300'"
      type="image"
      :alt="news?.title"
      class="w-full h-full object-cover"
    />
    <div
      class="absolute w-full h-full p-[4.5vw] text-white top-0 right-0 flex gap-5 items-center bg-neutral-800/35"
    >
      <div class="flex-1 leading-tight">
        <h3 class="text-[2.2vh] font-bold">{{ news?.title }}</h3>
        <p class="text-[1.2vh] font-semibold mt-[1vw]">{{ news?.abstract }}</p>
        <a
          v-if="showReadMore"
          class="flex items-center whitespace-nowrap gap-1 mt-[2vw]"
        >
          <span class="text-[1.6vh] font-semibold">Leggi tutto</span>
          <Icon icon="si:arrow-right-duotone" class="w-[4vw] h-[4vw]" />
        </a>
      </div>
      <div
        class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto"
      >
        <template v-if="news?.date">
          <p class="text-[1.8vh] font-bold leading-tight mb-0">
            {{ getDay(news?.date) }}
          </p>
          <p class="text-[1vh] font-semibold">{{ getMonth(news?.date) }}</p>
        </template>
        <template v-else>
          <p class="text-[1.8vh] font-bold leading-tight mb-0">
            {{ getDateEvent(news?.start_date, news?.end_date) }}
          </p>
          <p class="text-[1vh] font-semibold">
            {{ getMonthEvent(news?.start_date, news?.end_date) }}
          </p>
        </template>
      </div>
    </div>
  </div>
  <!-- Slide Modes -->
  <div
    v-else
    class="w-full h-full min-w-full bg-neutral-100 relative"
    @click="$emit('click', news)"
  >
    <MediaLoader
      :src="news?.image ? news.image[0]?.url : 'https://picsum.photos/200/300'"
      type="image"
      :alt="news?.title"
      class="w-full h-full object-cover"
    />
    <!-- Slim Slide -->
    <div
      v-if="mode === 'slim-slide'"
      class="absolute w-full h-full px-[4.5vw] pt-[3.8vw] pb-[2.2vw] text-white top-0 right-0 flex items-center bg-neutral-800/35"
    >
      <div class="w-full leading-tight">
        <h3 class="text-[2.2vh] font-bold">{{ news?.title }}</h3>
        <p class="text-[1.2vh] font-semibold mt-[1vw]">{{ news?.abstract }}</p>
      </div>
      <div class="h-full flex flex-col justify-between">
        <div
          class="border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto"
        >
          <template v-if="news?.date">
            <p class="text-[1.8vh] font-bold leading-tight mb-0">
              {{ getDay(news?.date) }}
            </p>
            <p class="text-[1vh] font-semibold">{{ getMonth(news?.date) }}</p>
          </template>
          <template v-else-if="news?.start_date || news?.end_date">
            <p class="text-[1.8vh] font-bold leading-tight mb-0">
              {{ getDateEvent(news?.start_date, news?.end_date) }}
            </p>
            <p class="text-[1vh] font-semibold">
              {{ getMonthEvent(news?.start_date, news?.end_date) }}
            </p>
          </template>
        </div>
        <a
          v-if="showReadMore"
          class="flex items-center whitespace-nowrap gap-1"
        >
          <span class="text-[1.6vh] font-semibold">Leggi tutto</span>
          <Icon icon="si:arrow-right-duotone" class="w-[4vw] h-[4vw]" />
        </a>
      </div>
    </div>
    <!-- Regular Slide -->
    <div
      v-else
      class="absolute w-full h-full p-[8vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
    >
      <div
        class="absolute top-[8vw] right-[8vw] border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto"
      >
        <template v-if="news?.date">
          <p class="text-[1.8vh] font-bold leading-tight mb-0">
            {{ getDay(news?.date) }}
          </p>
          <p class="text-[1vh] font-semibold">{{ getMonth(news?.date) }}</p>
        </template>
        <template v-else-if="news?.start_date || news?.end_date">
          <p class="text-[1.8vh] font-bold leading-tight mb-0">
            {{ getDateEvent(news?.start_date, news?.end_date) }}
          </p>
          <p class="text-[1vh] font-semibold">
            {{ getMonthEvent(news?.start_date, news?.end_date) }}
          </p>
        </template>
      </div>
      <div class="leading-tight pt-[17vw]">
        <h3 class="text-[3.8vh] font-bold">{{ news?.title }}</h3>
        <p class="text-[1.8vh] font-semibold mt-[2vw]">{{ news?.abstract }}</p>
        <a v-if="showReadMore" class="flex items-center gap-1 mt-[5vw]">
          <span class="text-[1.6vh] font-semibold">Leggi tutto</span>
          <Icon icon="si:arrow-right-duotone" class="w-[4vw] h-[4vw]" />
        </a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Icon } from "@iconify/vue";
import { getDay, getMonth, getThinMonth } from "@/helpers/helpers";
import { withDefaults } from 'vue';
import MediaLoader from '@/components/MediaLoader.vue';

const props = withDefaults(
  defineProps<{
    news: any;
    mode?: "grid" | "list" | "slide" | "slim-slide";
    showReadMore?: boolean;
  }>(),
  {
    news: {},
    mode: "list",
    showReadMore: false,
  }
);

defineEmits<{
  click: [news: any];
}>();

function getDateEvent(start: string, end: string): string {
  if (start && end) {
    return `${getDay(start)}-${getDay(end)}`;
  }
  return start ? getDay(start) : end ? getDay(end) : "";
}

function getMonthEvent(start: string, end: string): string {
  if (start || end) {
    const initMonth = start ? getMonth(start) : "";
    const endMonth = end ? getMonth(end) : "";

    if (initMonth && endMonth) {
      return initMonth === endMonth
        ? initMonth
        : `${getThinMonth(start)}-${getThinMonth(end)}`;
    }
    return initMonth || endMonth;
  }
  return "";
}
</script>
