import { openDB, DBSchema } from 'idb';

interface MediaItem {
  url: string;
  data: Blob | string;
  timestamp: number;
  type: 'image' | 'video' | 'map';
}

interface TotemMediaDB extends DBSchema {
  media: {
    key: string;
    value: MediaItem;
    indexes: { 'by-timestamp': number };
  };
  pendingUploads: {
    key: string;
    value: {
      url: string;
      data: FormData;
      timestamp: number;
    };
  };
}

const DB_NAME = 'totem-media-db';
const DB_VERSION = 1;

const dbPromise = openDB<TotemMediaDB>(DB_NAME, DB_VERSION, {
  upgrade(db) {
    // Media store
    const mediaStore = db.createObjectStore('media', { keyPath: 'url' });
    mediaStore.createIndex('by-timestamp', 'timestamp');

    // Store for pending uploads (for background sync)
    db.createObjectStore('pendingUploads', { keyPath: 'url' });
  },
});

export async function saveMedia(url: string, data: Blob | string, type: 'image' | 'video' | 'map') {
  const db = await dbPromise;
  const mediaItem: MediaItem = {
    url,
    data,
    type,
    timestamp: Date.now(),
  };
  await db.put('media', mediaItem);
}

export async function getMedia(url: string): Promise<MediaItem | undefined> {
  const db = await dbPromise;
  return db.get('media', url);
}

export async function removeMedia(url: string) {
  const db = await dbPromise;
  await db.delete('media', url);
}

export async function addPendingUpload(url: string, data: FormData) {
  const db = await dbPromise;
  await db.put('pendingUploads', {
    url,
    data,
    timestamp: Date.now(),
  });
}

export async function getPendingUploads() {
  const db = await dbPromise;
  return db.getAll('pendingUploads');
}

export async function removePendingUpload(url: string) {
  const db = await dbPromise;
  await db.delete('pendingUploads', url);
}

// Clean up old cached media (older than 7 days)
export async function cleanupOldMedia() {
  const db = await dbPromise;
  const sevenDaysAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
  
  const tx = db.transaction('media', 'readwrite');
  const index = tx.store.index('by-timestamp');
  
  let cursor = await index.openCursor();
  
  while (cursor) {
    if (cursor.value.timestamp < sevenDaysAgo) {
      await cursor.delete();
    }
    cursor = await cursor.continue();
  }
}
