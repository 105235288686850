// src/stores/webSocketStore.ts
import { defineStore } from 'pinia';
import { echo } from '../echo';
import {useMainStore} from "@/stores/mainStore";

export const useWebSocketStore = defineStore('websocket', {
  state: () => ({
    socket: null as WebSocket | null,
    isConnected: false,
    actionLastProcessed: new Map<string, number>()
  }),

  actions: {
    connectWebSocket(id: number) {
      // Open WebSocket connection
      console.log("Connecting to WebSocket server...");
      // this.socket = new WebSocket(`ws://localhost:6001/totem/${id}`);
      echo.channel('totem.' + id).listen('.message-totem', (e: any) => {

        this.handleEvent(e.message);
      });
      // this.startPingPong();
    },
    async handleEvent(message: any) {
      console.log('Received message from server:', message);
      
      // Check if this action was processed recently (within last minute)
      const now = Date.now();
      const lastProcessed = this.actionLastProcessed.get(message.action) || 0;
      const oneMinute = 60 * 1000; // 1 minute in milliseconds
      
      if (now - lastProcessed < oneMinute) {
        console.log(`Skipping duplicate action "${message.action}" (rate limited)`);
        return;
      }
      
      // Update the last processed time for this action
      this.actionLastProcessed.set(message.action, now);
      
      switch (message.action) {
        case "reload":
          const mainStore = useMainStore();
          await mainStore.loadSettings();
          break;
        case "update":
          await this.performUpdate();
          break;
        default:
          break;
      }
    },

    async performUpdate() {
      if ('serviceWorker' in navigator) {
        try {
          // Get the current registration
          const registration = await navigator.serviceWorker.getRegistration();
          
          if (registration) {
            // Check if there's a waiting worker
            if (registration.waiting) {
              // Send skip waiting message to activate the new version
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
            
            // Unregister current service worker
            await registration.unregister();
            
            console.log('Service Worker updated successfully');
            
            // Reload the page after a short delay to ensure the new version is activated
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } catch (error) {
          console.error('Error updating Service Worker:', error);
        }
      }
    },

    startPingPong() {
      console.log("Starting ping  ...");
      setInterval(() => {
        console.log("Sending ping to server...");
        echo.connector.pusher.connection.send_event(
          "client-ping",
          { message: "ping" },
          echo.options.key
        );
      }, 10000);
    }
  }
});
