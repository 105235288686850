<template>
  <div class="w-full h-full">
    <div class="flex flex-col h-full">
      <div class="bg-neutral-100 h-[56vw] relative">
        <template v-for="(img, index) in data.image" :key="index">
          <MediaLoader
            type="image"
            :src="img?.url ? img.url : ''"
            class="w-full h-full object-cover"
          />
        </template>

        <div
          class="absolute w-full h-full p-[6vw] text-white top-0 right-0 flex flex-col bg-neutral-800/35"
        >
          <div
            class="border border-white rounded w-[14vw] h-[14vw] flex flex-col justify-center items-center ms-auto"
          >
            <template v-if="data?.date">
              <p class="text-[2.2vh] font-bold leading-tight mb-0">
                {{ getDay(data?.date) }}
              </p>
              <p class="text-[1.4vh] font-semibold">
                {{ getMonth(data?.date) }}
              </p>
            </template>
            <template v-else-if="data?.start_date && data?.end_date">
              <p class="text-[2.2vh] font-bold leading-tight mb-0">
                {{ data?.start_date ? getDay(data?.start_date) : "" }} -
                {{ data?.end_date ? getDay(data?.end_date) : "" }}
              </p>
              <p class="text-[1.4vh] font-semibold">
                {{ data?.start_date ? getMonth(data?.start_date) : "" }}
              </p>
            </template>
          </div>
          <h3 class="text-[3.8vh] font-bold leading-tight mt-auto">
            {{ data?.title }}
          </h3>
        </div>
      </div>
      <div class="h-full relative">
        <div
          class="w-full h-[1.2vh] absolute z-10 top-0 left-0 bg-gradient-to-b from-black/20 to-transparent"
        ></div>
        <div class="h-full overflow-y-auto scrollbar-none">
          <!-- ABSTRACT -->
          <div
            class="gradient-gray p-[6vw] pb-[12vw] text-gray-theme text-[1.8vh] font-semibold"
          >
            {{ data?.abstract }}
          </div>
          <div
            class="rounded-t-3xl mt-[-4vh] bg-white pt-[6vw] pb-[35vh] shadow-top"
          >
            <!-- QR CODE -->
            <div class="ps-[6vw] pe-[8vw] pt-[2vw] pb-[2vw]">
              <div
                class="relative gradient-gray rounded-3xl p-[4vw] pe-[21vw] text-gray-theme flex gap-[4vw] items-center"
              >
                <img src="@/assets/images/qr-tel.png" class="w-[10vw]" />
                <div class="leading-tight">
                  <h4 class="text-[2.8vh] font-bold">Portami con te!</h4>
                  <p class="text-[1.8vh] font-semibold mt-1">
                    Scansiona il codice QR e leggi questa pagina sul tuo
                    smartphone
                  </p>
                </div>
                <div
                  class="absolute top-[-2vw] right-[-2vw] rounded-xl shadow-center bg-white p-[2vw]"
                >
                  <img
                    :src="'data:image/png;base64,' + data?.qr_code"
                    alt="QR Code"
                    class="w-[16vw]"
                  />
                </div>
              </div>
            </div>
            <!-- TESTO -->
            <div class="prose serif p-[6vw]">
              <div class="text-[1.6vh] leading-relaxed space-y-6">
                <p v-html="data?.body" />
              </div>
            </div>
            <!-- ALLEGATI -->
            <!-- <div class="px-[6vw] pb-[5vh]" v-if="data?.files?.length>0">
              <div
                class="gradient-gray rounded-3xl p-[4vw] text-gray-theme flex flex-col gap-5"
              >
                <template
                  v-if="data?.files?.length>0"
                  v-for="(a, i) in data.files"
                  :key="i"
                >
                  <div
                    class="flex gap-5 items-center text-[1.8vh] font-semibold leading-tight bg-red-200"
                    @click="openPdfViewer(a.url)"
                  >
                    <Icon icon="ci:link" class="w-[4vw] h-[4vw]" />
                    <span class="flex-1">{{ a.file_name }}</span>
                  </div>

                  <PdfViewerComponent v-if="showPdfViewer" :src="currentPdfUrl" />
                </template>
              </div>
            </div> -->
            <!-- PLACEHOLDER PER MAPPA  -->
            <template v-if="data?.position">
              <MapComponent
                :latitude="data.position?.latitude"
                :address="data.position?.address"
                :longitude="data.position?.longitude"
                :fullScreen="isMapFullScreen"
                @mapEnterFullScreen="mapEnterFullScreen"
                @mapExitFullScreen="mapExitFullScreen"
              />
            </template>
            <!-- GALLERY -->
            <template v-if="images?.length > 0">
              <GalleryComponent
                :contents="images"
                :fullScreen="galleryState.type === 'image'"
                @galleryEnterFullScreen="() => setGalleryState('image')"
                @galleryExitFullScreen="resetGalleryState"
                :type="'image'"
              />
            </template>
            <template v-if="videos?.length > 0">
              <div>
                <div
                  v-for="(media, index) in videos"
                  :key="index"
                  class="relative my-3 flex justify-center bg-black"
                >
                  >
                  <div
                    v-if="!videoStates[index]"
                    class="w-full h-full absolute top-0 left-0 cursor-pointer z-20"
                    @click="playVideo(index)"
                  >
                    <img
                      src="@/assets/images/play-icon.svg"
                      class="absolute inset-0 m-auto w-20 h-20 pointer-events-none"
                    />
                  </div>
                  <video
                    :ref="
                      (el) => {
                        if (el) videoRefs[index] = el;
                      }
                    "
                    :src="media.url"
                    class="h-[56vw]"
                    :controls="videoStates[index]"
                    controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
                    disablePictureInPicture
                    preload="auto"
                    @play="updatePlayingState(index, true)"
                    @pause="updatePlayingState(index, false)"
                    @ended="updatePlayingState(index, false)"
                  />
                </div>
              </div>
            </template>
            <template v-if="data?.correlated?.length > 0">
              <!-- CORRELATED -->
              <div v-for="(group, index) in data?.correlated" :key="index" class="mb-[5vw]">
                <h3 class="text-[2.0vh] font-bold leading-tight mb-[2vw] uppercase pl-[6vw] opacity-80">
                  {{ group?.title }}
                </h3>
                <div
                  class="flex flex-col gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
                >
                  {{ data?.details }}
                  <template v-for="(item, index) in group?.contentList" :key="index">
                    <NewsEventCard :news="item.relation" mode="list" :show-read-more="true" @click="openCorrelated" />
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, computed, watch, defineEmits, defineProps } from "vue";
import { Icon } from "@iconify/vue";
import MediaLoader from './MediaLoader.vue';
import MapComponent from "@/components/MapComponent.vue";
import PdfViewerComponent from "./PdfViewerComponent.vue";
import GalleryComponent from "./GalleryComponent.vue";
import NewsEventCard from "@/components/news-event/NewsEventCard.vue"; 
import { getDay, getMonth } from "@/helpers/helpers";
import { useLayoutStore } from "@/stores/layoutStore";

const layoutStore = useLayoutStore();

const emit = defineEmits(['go-back', 'open-correlated']);

const props = defineProps<{
  data: any;
}>();

//aggiungere tutte le condizioni in or 
const isMapFullScreen = ref(false);
const galleryState = ref({
  type: null as "image" | "video" | null,
});
const showPdfViewer = ref(false);
const currentPdfUrl = ref("");
const videoStates = ref([]);
const videoRefs = ref<HTMLVideoElement[]>([]);

const openPdfViewer = (url: string) => {
  console.log("openPDF clicked", url);
  currentPdfUrl.value = url;
  showPdfViewer.value = true;
};

const isLayoutBusy = computed(
  () =>
    isMapFullScreen.value ||
    galleryState.value.type !== null ||
    showPdfViewer.value
);

const images = computed(() => {
  return props.data?.gallery?.map((image: any) => image.url) || [];
});

const videos = computed(() => {
  if (!props.data?.videos) return [];
  // Initialize video states if needed
  if (videoStates.value.length !== props.data.videos.length) {
    videoStates.value = props.data.videos.map(() => false);
  }
  return props.data.videos.map((video, index) => ({
    ...video,
    index,
  }));
});

const updatePlayingState = (index: number, isPlaying: boolean) => {
  videoStates.value[index] = isPlaying;
};

const mapEnterFullScreen = () => {
  isMapFullScreen.value = true;
};

const mapExitFullScreen = () => {
  isMapFullScreen.value = false;
};

const setGalleryState = (type: "image" | "video") => {
  galleryState.value.type = type;
};

const resetGalleryState = () => {
  galleryState.value.type = null;
};

const handleGoBack = () => {
  console.log("going back");
  if (!!isMapFullScreen.value) mapExitFullScreen();
  else if (galleryState.value.type !== null) resetGalleryState();
  else if (!!showPdfViewer.value) {
    showPdfViewer.value = false;
    currentPdfUrl.value = "";
  } 
  else {
    emit('go-back');
  }
};

const playVideo = (index: number) => {
  if (videoRefs.value[index]) {
    videoRefs.value[index].play();
  }
};

const fetchCorrelatedContent = () => {
  if (!('correlated' in props.data)) {
    layoutStore.fetchCorrelated(props.data.type, props.data.id)
      .then(result => {
        console.log('Correlated content:', result);
        if (result && 'correlated' in result) {
          console.log("Correlated content2:", result);
          props.data.correlated = result.correlated;
        }
      })
      . catch(error => console.error('Error fetching correlated content:', error));
  }
};

watch(() => props.data, fetchCorrelatedContent, { immediate: true });

onMounted(() => {
  window.addEventListener("navigation:goBack", handleGoBack);
});

onBeforeUnmount(() => {
  window.removeEventListener("navigation:goBack", handleGoBack);
});

function openCorrelated(item: any) {
    console.log(item);
    emit('open-correlated', item);
}
</script>
