import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-full bg-white relative" }
const _hoisted_2 = { class: "absolute bottom-10 left-0 w-full h-[20%]" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "absolute w-full h-full px-[4.5vw] pt-[3.8vw] pb-[2.2vw] text-white top-0 right-0 flex items-center bg-neutral-800/35" }
const _hoisted_5 = { class: "w-full leading-tight" }
const _hoisted_6 = { class: "text-[2.2vh] font-bold" }
const _hoisted_7 = { class: "text-[1.2vh] font-semibold mt-[1vw]" }
const _hoisted_8 = { class: "h-full flex flex-col justify-between" }
const _hoisted_9 = { class: "border border-white rounded w-[10vw] h-[10vw] flex flex-col justify-center items-center mb-auto ms-auto" }
const _hoisted_10 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_11 = { class: "text-[1vh] font-semibold" }
const _hoisted_12 = { class: "text-[1.8vh] font-bold leading-tight mb-0" }
const _hoisted_13 = { class: "text-[1vh] font-semibold" }
const _hoisted_14 = { class: "flex items-center whitespace-nowrap gap-1" }

import {
  ref,
  reactive,
  watch,
  onMounted,
  computed,
} from "vue";
import { Icon } from "@iconify/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, EffectFade } from "swiper/modules";
import { getDay, getMonth, getThinMonth } from "@/helpers/helpers";
import defaultPoiIcon from "@/assets/icons/map-poi.png";
import selectedPoiIcon from "@/assets/icons/map-selected-poi.png";
import userPositionIconUrl from "@/assets/icons/map-user-position.png";
import MediaLoader from '../MediaLoader.vue';

// Define props with default values
const defaultMarkerHeight = 60;

export default /*@__PURE__*/_defineComponent({
  __name: 'NewsEventMap',
  props: {
    newsList: { default: [] },
    play: { type: Boolean, default: true },
    delay: { default: 5000 },
    userPosition: { default: null }
  },
  emits: ["selectedItem"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

// Define emits
const emit = __emit;

// Map settings
const zoom = ref(12);
const mapRef = ref(null);
const swiperRef = ref<SwiperType | null>(null);

// Hide controls and customize map appearance
const mapOptions = {
  zoomControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }]
    }
  ]
};

const mapCenter = ref({ lat: 45.4642, lng: 9.19 }); // Milan center coordinates
const activeMarkerIndex = ref(0);

const markers = computed(() => {
  return props.newsList.map((item, index) => ({
      position: {
        lat: item?.position?.latitude,
        lng: item?.position?.longitude,
      },
      isActive: index === activeMarkerIndex.value,
  }));
});

// Function to get marker dimensions based on desired height
function getMarkerDimensions(imageUrl: string, desiredHeight: number): Promise<{ width: number, height: number }> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      resolve({
        width: Math.round(desiredHeight * aspectRatio),
        height: desiredHeight
      });
    };
    img.src = imageUrl;
  });
}

const activeMarkerHeight = defaultMarkerHeight * 1.5;
// Initialize marker dimensions
const defaultMarkerDimensions = ref({ width: 0, height: defaultMarkerHeight });
const activeMarkerDimensions = ref({ width: 0, height: activeMarkerHeight });
const userPositionDimensions = ref({ width: 0, height: defaultMarkerHeight });

// Update marker dimensions when component is mounted
onMounted(async () => {
  defaultMarkerDimensions.value = await getMarkerDimensions(defaultPoiIcon, defaultMarkerHeight);
  activeMarkerDimensions.value = await getMarkerDimensions(selectedPoiIcon, activeMarkerHeight);
  userPositionDimensions.value = await getMarkerDimensions(userPositionIconUrl, defaultMarkerHeight);
});

const defaultMarkerIcon = computed(() => ({
  url: defaultPoiIcon,
  size: defaultMarkerDimensions.value,
  scaledSize: defaultMarkerDimensions.value,
  anchor: { 
    x: defaultMarkerDimensions.value.width / 2, 
    y: defaultMarkerDimensions.value.height 
  },
}));

const activeMarkerIcon = computed(() => ({
  url: selectedPoiIcon,
  size: activeMarkerDimensions.value,
  scaledSize: activeMarkerDimensions.value,
  anchor: { 
    x: activeMarkerDimensions.value.width / 2, 
    y: activeMarkerDimensions.value.height 
  },
}));

const userPositionIcon = computed(() => ({
  url: userPositionIconUrl,
  size: userPositionDimensions.value,
  scaledSize: userPositionDimensions.value,
  anchor: { 
    x: userPositionDimensions.value.width / 2, 
    y: userPositionDimensions.value.height 
  },
}));

const boundsAdjusted = ref(false);

// Function to get map bounds including all markers
function getMarkerBounds(): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();
  
  // Add news markers to bounds
  markers.value.forEach((marker) => {
    bounds.extend(marker.position);
  });
  
  // Add static marker to bounds if it exists
  if (props.userPosition) {
    bounds.extend(props.userPosition);
  }
  
  return bounds;
}

const onTilesLoaded = () => {

  if (boundsAdjusted.value ) return;
  if (!mapRef.value) return;
  

  const mapRefVal: any = mapRef.value;

  try {
    // Access the map instance using $mapObject
    const gmap = mapRefVal.$mapObject;
    if (!gmap) {
      return;
    }

    // Get map dimensions
    const mapDiv = gmap.getDiv();
    const mapHeight = mapDiv.clientHeight;
    const bottomPadding = Math.floor(mapHeight * 0.25); 

    const padding = {
      top: 50,      
      right: 5,    
      bottom: bottomPadding , 
      left: 5      
    };

    const bounds = getMarkerBounds();
    gmap.fitBounds(bounds, padding);
    boundsAdjusted.value = true;
  } catch (error) {
    console.error(">>> Error setting bounds:", error);
  }
};

// Reset the bounds adjusted flag when markers change
watch(() => markers.value, () => {
  boundsAdjusted.value = false;
}, { deep: true });

// Also handle the map loaded event
const onMapLoaded = () => {
  onTilesLoaded();
};

// onMounted(() => {
//   console.log(">>> Component mounted, map reference:", mapRef.value);
// });

function onSwiper(swiper: SwiperType) {
  swiperRef.value = swiper;
  if (props.play) {
    swiper.autoplay.start();
  } else {
    swiper.autoplay.stop();
  }
}

function selectedItem(item: any) {
    emit("selectedItem", item);
}

function handleMarkerClick(index: number) {
  activeMarkerIndex.value = index;
  if (swiperRef.value) {
    swiperRef.value.slideToLoop(index);
  }
}

watch(() => props.play, (newVal) => {
  console.log("Play prop changed:", newVal);
  if (swiperRef.value) {
    if (newVal) {
      console.log("Starting autoplay");
      swiperRef.value.autoplay.start();
    } else {
      console.log("Stopping autoplay");
      swiperRef.value.autoplay.stop();
    }
  }
}, { immediate: true }); 



function onSlideChangeStart() {
  console.log("Slide change started");
  updateActiveMarker();
}

function updateActiveMarker() {
  if (swiperRef.value) {
    const swiper = swiperRef.value;
    const realIndex = swiper.realIndex;
    
    if (activeMarkerIndex.value !== realIndex) {
      activeMarkerIndex.value = realIndex;
    }
  }
}

function getDateEvent(start: string, end: string): string {
  if (start && end) {
    return `${getDay(start)}-${getDay(end)}`;
  }

  return start ? getDay(start) : end ? getDay(end) : "";
}

function getMonthEvent(start: string, end: string): string {
  if (start || end) {
    const initMonth = start ? getMonth(start) : "";
    const endMonth = end ? getMonth(end) : "";

    if (initMonth && endMonth) {
      return initMonth === endMonth
        ? initMonth
        : `${getThinMonth(start)}-${getThinMonth(end)}`;
    }

    return initMonth || endMonth;
  }
  return "";
}

const touchStartX = ref(0);

function onTouchStart(swiper: any) {
  touchStartX.value = swiper.touches.startX;
}

function onTouchEnd(swiper: any) {
  const touchEndX = swiper.touches.currentX;
  const diff = touchEndX - touchStartX.value;
  
  // If it was a significant swipe
  if (Math.abs(diff) > 50) {
    console.log("Touch ended, updating marker");
    updateActiveMarker();
  }
}

return (_ctx: any,_cache: any) => {
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GMapMap, {
      ref_key: "mapRef",
      ref: mapRef,
      zoom: zoom.value,
      options: mapOptions,
      "map-type-id": "roadmap",
      center: mapCenter.value,
      class: "w-full h-full",
      onTilesloaded: onTilesLoaded,
      onLoad: onMapLoaded
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(markers.value, (marker, index) => {
          return (_openBlock(), _createBlock(_component_GMapMarker, {
            key: index,
            position: marker.position,
            clickable: true,
            icon: marker.isActive ? activeMarkerIcon.value : defaultMarkerIcon.value,
            onClick: ($event: any) => (handleMarkerClick(index))
          }, null, 8, ["position", "icon", "onClick"]))
        }), 128)),
        (props.userPosition)
          ? (_openBlock(), _createBlock(_component_GMapMarker, {
              key: 0,
              position: props.userPosition,
              icon: userPositionIcon.value
            }, null, 8, ["position", "icon"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["zoom", "center"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Swiper), {
        spaceBetween: 25,
        centeredSlides: true,
        slidesPerView: 1.3,
        loop: true,
        autoplay: { delay: _ctx.delay, disableOnInteraction: false },
        effect: 'none',
        modules: [_unref(Autoplay), _unref(EffectFade)],
        touchRatio: 1,
        followFinger: true,
        threshold: 10,
        class: "mySwiper h-full px-4",
        onSwiper: onSwiper,
        onSlideChangeTransitionStart: onSlideChangeStart,
        onTouchEnd: onTouchEnd,
        onTouchStart: onTouchStart
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsList, (news, index) => {
            return (_openBlock(), _createBlock(_unref(SwiperSlide), {
              key: index,
              class: "h-full"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "w-full h-full bg-neutral-100 relative rounded-lg overflow-hidden",
                  onClick: ($event: any) => (selectedItem(news))
                }, [
                  _createVNode(MediaLoader, {
                    type: "image",
                    src: news?.image ? news.image[0]?.url : 'https://picsum.photos/200/300',
                    alt: news.title,
                    class: "w-full h-full object-cover"
                  }, null, 8, ["src", "alt"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("h3", _hoisted_6, _toDisplayString(news.title), 1),
                      _createElementVNode("p", _hoisted_7, _toDisplayString(news.abstract), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        (news?.date)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                              _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(getDay)(news?.date)), 1),
                              _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(getMonth)(news?.date)), 1)
                            ], 64))
                          : (news?.start_date || news?.end_date)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createElementVNode("p", _hoisted_12, _toDisplayString(getDateEvent(news?.start_date, news?.end_date)), 1),
                                _createElementVNode("p", _hoisted_13, _toDisplayString(getMonthEvent(news?.start_date, news.end_date)), 1)
                              ], 64))
                            : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("a", _hoisted_14, [
                        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-[1.6vh] font-semibold" }, "Leggi tutto", -1)),
                        _createVNode(_unref(Icon), {
                          icon: "si:arrow-right-duotone",
                          class: "w-[4vw] h-[4vw]"
                        })
                      ])
                    ])
                  ])
                ], 8, _hoisted_3)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["autoplay", "modules"])
    ])
  ]))
}
}

})