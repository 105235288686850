import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "h-full bg-white" }
const _hoisted_2 = {
  key: 0,
  class: "w-full h-full"
}
const _hoisted_3 = {
  key: 1,
  class: "grid grid-cols-2 gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
}
const _hoisted_4 = {
  key: 2,
  class: "flex flex-col gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
}
const _hoisted_5 = {
  key: 4,
  class: "detail-view"
}

import { ref, watch, onMounted, computed } from "vue";
import NewsEventDetail from "@/components/NewsEventDetail.vue";
import NewsEventMap from "@/components/news-event/NewsEventMap.vue";
import NewsEventCard from "@/components/news-event/NewsEventCard.vue";
import { useLayoutStore } from "@/stores/layoutStore";
import { useSettingsStore } from "@/stores/settingsStore";  
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, EffectFade } from "swiper/modules";

interface ComponentInfo {
  view_mode: string;
  items: any[];
  duration: number;
  // add other properties here
}

export default /*@__PURE__*/_defineComponent({
  __name: 'NewsEventsPlayable',
  props: {
    layoutPosition: {},
    componentInfo: {}
  },
  setup(__props: any) {

const layoutStore = useLayoutStore();
const settingsStore = useSettingsStore();
const userPosition = ref(settingsStore.position ? { lat: settingsStore.position.latitude, lng: settingsStore.position.longitude } : null);
const data: any = ref([]);
const swiperRef = ref<SwiperType | null>(null);

const props = __props;

// State management
const viewMode = ref(
  props.layoutPosition == "center" ? props.componentInfo?.view_mode : "scroll"
);
const isSlim = ref(props.layoutPosition != "center");
const newsList = ref(props.componentInfo?.items || []); // Possible values: 'grid', 'list', 'scroll'
const newsStack = ref<any[]>([]);
const currentNews = computed(() => newsStack.value[newsStack.value.length - 1] || null);
const isDetailViewVisible = computed(() => currentNews.value !== null);
const duration = ref(
  props.componentInfo?.duration ? props.componentInfo?.duration * 1000 : 5000
);

// Methods for handling user actions
function openDetail(news: any) {
  console.log("openingDetail", news);
  newsStack.value.push(news);
  layoutStore.requestFullScreen(props.layoutPosition);
}

function closeDetail() {
  newsStack.value.pop();
}

watch(isDetailViewVisible, (newVal) => {
  console.log("isDetailViewVisible changed", newVal)
  layoutStore.isLayoutBusy = newVal;
  if (!newVal) layoutStore.releaseFullScreen();
  if (swiperRef.value) {
    if (newVal) {
      swiperRef.value.autoplay.stop(); // Pause Swiper when detail view is visible
    } else {
      swiperRef.value.autoplay.start(); // Resume Swiper when detail view is hidden
    }
  }
});

watch(
  () => layoutStore.isSectionExpanded(props.layoutPosition),
  (sectionExpanded: boolean) => {
    if (!sectionExpanded && isDetailViewVisible.value) {
      newsStack.value = []; // Clear the local stack instead of using store
    }
  }
);

function onSwiper(swiper: SwiperType) {
  swiperRef.value = swiper; // Set the Swiper instance
}
onMounted(() => {
  console.log("NewsEventsPlayable mounted", props.componentInfo);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (viewMode.value === 'map')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(NewsEventMap, {
            "news-list": newsList.value,
            play: !isDetailViewVisible.value,
            delay: duration.value,
            "user-position": userPosition.value,
            onSelectedItem: openDetail
          }, null, 8, ["news-list", "play", "delay", "user-position"])
        ]))
      : (viewMode.value === 'grid')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsList.value, (news, index) => {
              return (_openBlock(), _createBlock(NewsEventCard, {
                key: index,
                news: news,
                mode: "grid",
                onClick: openDetail
              }, null, 8, ["news"]))
            }), 128))
          ]))
        : (viewMode.value === 'list')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createTextVNode(_toDisplayString(data.value?.details) + " ", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsList.value, (news, index) => {
                return (_openBlock(), _createBlock(NewsEventCard, {
                  key: index,
                  news: news,
                  mode: "list",
                  onClick: openDetail
                }, null, 8, ["news"]))
              }), 128))
            ]))
          : (viewMode.value === 'scroll')
            ? (_openBlock(), _createBlock(_unref(Swiper), {
                key: 3,
                spaceBetween: 1,
                loop: true,
                autoplay: { delay: duration.value, disableOnInteraction: false },
                effect: 'none',
                modules: [_unref(Autoplay), _unref(EffectFade)],
                class: "mySwiper h-full",
                onSwiper: onSwiper
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(newsList.value, (news, index) => {
                    return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                      key: index,
                      class: "h-full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(NewsEventCard, {
                          news: news,
                          mode: isSlim.value ? 'slim-slide' : 'slide',
                          "show-read-more": true,
                          onClick: openDetail
                        }, null, 8, ["news", "mode"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["autoplay", "modules"]))
            : _createCommentVNode("", true),
    (isDetailViewVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(NewsEventDetail, {
            data: currentNews.value,
            onGoBack: closeDetail,
            onOpenCorrelated: openDetail
          }, null, 8, ["data"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})