<template>
  <div class="h-full bg-white">
    <template v-if="viewMode === 'map'">
      <div class="w-full h-full">
        <NewsEventMap
        :news-list="newsList"
        :play="!isDetailViewVisible"
        :delay="duration"
        :user-position="userPosition"
        @selectedItem="openDetail"
        />
      </div>
    </template>
    <template v-else-if="viewMode === 'grid'">
      <!-- Grid View -->
      <div
        class="grid grid-cols-2 gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
      >
        <template v-for="(news, index) in newsList" :key="index">
          <NewsEventCard :news="news" mode="grid" @click="openDetail" />
        </template>
      </div>
    </template>
    <template v-else-if="viewMode === 'list'">
      <!-- List View -->
      <div
        class="flex flex-col gap-[1.5vw] max-h-full overflow-y-auto scrollbar-none"
      >
        {{ data?.details }}
        <template v-for="(news, index) in newsList" :key="index">
          <NewsEventCard :news="news" mode="list" @click="openDetail" />
        </template>
      </div>
    </template>
    <template v-else-if="viewMode === 'scroll'">
      <!-- Scroll View (Full-Screen and Reduced) -->
      <swiper
        :spaceBetween="1"
        :loop="true"
        :autoplay="{ delay: duration, disableOnInteraction: false }"
        :effect="'none'"
        :modules="[Autoplay, EffectFade]"
        class="mySwiper h-full"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(news, index) in newsList"
          :key="index"
          class="h-full"
        >
          <NewsEventCard 
            :news="news" 
            :mode="isSlim ? 'slim-slide' : 'slide'" 
            :show-read-more="true" 
            @click="openDetail" 
          />
        </swiper-slide>
      </swiper>
    </template>

    <template v-if="isDetailViewVisible">
      <!-- Detail View -->
      <div class="detail-view">
        <NewsEventDetail 
          :data="currentNews"
          @go-back="closeDetail"
          @open-correlated="openDetail"
        />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, onMounted, computed } from "vue";
import NewsEventDetail from "@/components/NewsEventDetail.vue";
import NewsEventMap from "@/components/news-event/NewsEventMap.vue";
import NewsEventCard from "@/components/news-event/NewsEventCard.vue";
import { useLayoutStore } from "@/stores/layoutStore";
import { useSettingsStore } from "@/stores/settingsStore";  
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperType } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, EffectFade } from "swiper/modules";

const layoutStore = useLayoutStore();
const settingsStore = useSettingsStore();
const userPosition = ref(settingsStore.position ? { lat: settingsStore.position.latitude, lng: settingsStore.position.longitude } : null);
const data: any = ref([]);
const swiperRef = ref<SwiperType | null>(null);

interface ComponentInfo {
  view_mode: string;
  items: any[];
  duration: number;
  // add other properties here
}
const props = defineProps<{
  layoutPosition: "top" | "center" | "bottom";
  componentInfo: ComponentInfo | null;
}>();

// State management
const viewMode = ref(
  props.layoutPosition == "center" ? props.componentInfo?.view_mode : "scroll"
);
const isSlim = ref(props.layoutPosition != "center");
const newsList = ref(props.componentInfo?.items || []); // Possible values: 'grid', 'list', 'scroll'
const newsStack = ref<any[]>([]);
const currentNews = computed(() => newsStack.value[newsStack.value.length - 1] || null);
const isDetailViewVisible = computed(() => currentNews.value !== null);
const duration = ref(
  props.componentInfo?.duration ? props.componentInfo?.duration * 1000 : 5000
);

// Methods for handling user actions
function openDetail(news: any) {
  console.log("openingDetail", news);
  newsStack.value.push(news);
  layoutStore.requestFullScreen(props.layoutPosition);
}

function closeDetail() {
  newsStack.value.pop();
}

watch(isDetailViewVisible, (newVal) => {
  console.log("isDetailViewVisible changed", newVal)
  layoutStore.isLayoutBusy = newVal;
  if (!newVal) layoutStore.releaseFullScreen();
  if (swiperRef.value) {
    if (newVal) {
      swiperRef.value.autoplay.stop(); // Pause Swiper when detail view is visible
    } else {
      swiperRef.value.autoplay.start(); // Resume Swiper when detail view is hidden
    }
  }
});

watch(
  () => layoutStore.isSectionExpanded(props.layoutPosition),
  (sectionExpanded: boolean) => {
    if (!sectionExpanded && isDetailViewVisible.value) {
      newsStack.value = []; // Clear the local stack instead of using store
    }
  }
);

function onSwiper(swiper: SwiperType) {
  swiperRef.value = swiper; // Set the Swiper instance
}
onMounted(() => {
  console.log("NewsEventsPlayable mounted", props.componentInfo);
});
</script>

<style scoped>
.detail-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  transform: translateX(0%);
  z-index: 3;
}
</style>
